let origin = window.ORIGIN;
// 问卷列表
const getTopicListURL = `${origin}/gateway/hc-govern/miniapi/qu/topicList`;
// 查询话题详情
const getTopicInfoByIdURL = `${origin}/gateway/hc-neighbour/topic/getTopicInfoById`;
// 点赞
const addPraiseURL = `${origin}/gateway/hc-neighbour/topic/addPraise`;
// 取消点赞
const cancelPraiseURL = `${origin}/gateway/hc-neighbour/topic/cancelPraise`;
// 进行评论
const addCommentURL = `${origin}/gateway/hc-neighbour/topic/addComment`;
// 话题评论列表分页查询
const getTopicCommentList = `${origin}/gateway/hc-neighbour/topic/getTopicCommentList`;
// 评论回复列表分页查询
const getTopicReplyList = `${origin}/gateway/hc-neighbour/topic/getTopicReplyList`;

// 用户信息接口
const userInfoUrl = `${origin}/gateway/hc-mini/user/mini/user-info`;
// 查询产权人认证审核状态
const getUserHouseOwnerAuditStatusUrl = `${origin}/gateway/blade-user/user/getUserHouseOwnerAuditStatus`;
// 产权人身份认证信息提交
const applyHouseOwnerAuditUrl = `${origin}/gateway/blade-user/user/applyHouseOwnerAudit`;
// 查询用户提交过的真实姓名
const getProvidedRealNameUrl = `${origin}/gateway/blade-user/user/getProvidedRealName`;

// 调查问卷详情
const getTopicInfo = `${origin}/gateway/hc-govern/miniapi/qu/topicInfo`;
// 我参与的问卷详情
const getmyMyTopicInfo = `${origin}/gateway/hc-govern/miniapi/qu/myTopicInfo`;
// 提交问卷
const getSubmitQuestionnaire = `${origin}/gateway/hc-govern/miniapi/qu/submitQuestionnaire`;

export {
  getTopicListURL,
  getTopicInfoByIdURL,
  addPraiseURL,
  cancelPraiseURL,
  addCommentURL,
  getTopicCommentList,
  getTopicReplyList,
  userInfoUrl,
  getUserHouseOwnerAuditStatusUrl,
  applyHouseOwnerAuditUrl,
  getProvidedRealNameUrl,
  getTopicInfo,
  getmyMyTopicInfo,
  getSubmitQuestionnaire,
};
